import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from '@app/core/components/modal/modal.component';
import { FinancialsDataService } from '@app/core/data-services/financials/financials-data.service';
import { ProviderConfigFeature } from '@app/core/directives/provider-config-feature-flag/provider-config-feature.enum';
import { ProviderPaymentPreference } from '@app/core/enums/provider-payment-reference';
import { PaymentMissedItemResponse } from '@app/core/models';
import { PaymentMissedDetailsItemResponse } from '@app/core/models/payment/payment-missed-details-item-response';
import { PaymentMissedDetailsPaymentHistoryItemResponse } from '@app/core/models/payment/payment-missed-details-payment-history-item-response';
import { PaymentMissedProviderActivityItemResponse } from '@app/core/models/payment/payment-missed-provider-activity-item-response';
import { HelperService } from '@app/core/services/helper.service';
import { NotificationService } from '@app/core/services/notification.service';
import { UserSettingsService } from '@app/core/services/user-settings/user-settings.service';
import { AppButton } from '@app/shared/interfaces/button.interface';
import { SearchRequest } from '@app/shared/interfaces/search-request.interface';
import { SearchConfig } from '@app/shared/interfaces/search.interface';
import { environment } from '@environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { concatMap, filter, map, take, takeUntil } from 'rxjs/operators';
import { MissedPaymentsService } from '../services/missed-payments.service';
import { MissedPaymentDetailsService } from './missed-payments-details.service';
import { PaymentService } from '@app/core/services/payment.service';
import { ModalPdfViewerComponent } from '@app/core/components/modal-pdf-viewer/modal-pdf-viewer.component';

@Component({
  selector: 'sliqpay-missed-payments-details',
  templateUrl: './missed-payments-details.component.html',
  styleUrls: ['./missed-payments-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissedPaymentsDetailsComponent implements OnInit, OnDestroy {
  LANG_CONFIG_KEY = 'missed_payment_details';

  ProviderConfigFeature = ProviderConfigFeature;

  itemPerPage = environment.itemPerPage;
  page = 0;
  currentTab = 0;

  plan!: PaymentMissedItemResponse;
  ddrID = '';
  isMissedPayment = 'false';
  showRedTotalOverDueAmount = false;
  showRedCurrentMissedPayments = false;
  mpPlanDetails!: PaymentMissedDetailsItemResponse;
  mpDetails$ = new BehaviorSubject<PaymentMissedDetailsItemResponse[] | null>(null);
  mpDetailsPaymentHistoryFiltered$ = new BehaviorSubject<PaymentMissedDetailsPaymentHistoryItemResponse[] | null>(null);
  mpProviderActivityFiltered$ = new BehaviorSubject<PaymentMissedProviderActivityItemResponse[] | null>(null);
  totalProviderPaidToDate = 0;
  totalPaymentForecast = 0;
  totalPaymentSummary = 0;

  searchWidgetConfig$!: Observable<SearchConfig[]>;
  previewBtnConfig$!: Observable<AppButton>;
  sendInvoiceBtnConfig$!: Observable<AppButton>;
  escalateBtnConfig$!: Observable<AppButton>;

  unsubscribe$ = new Subject<boolean>();
  searchData: SearchRequest = {};

  private subs = new Subscription();

  constructor(
    public userSettingsService: UserSettingsService,
    private notificationService: NotificationService,
    private service: MissedPaymentDetailsService,
    private helperService: HelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private missedPaymentsService: MissedPaymentsService,
    private modal: NgbModal,
    private financialService: FinancialsDataService,
    private paymentService: PaymentService
  ) {
    this.subs.add(
      this.activatedRoute.params.subscribe((params) => {
        this.isMissedPayment = params['isMissedPayment'];
        this.ddrID = params['ddrID'];

        this.navigationBackRoute();
      })
    );
  }

  ngOnInit(): void {
    this.setConfig();

    if (this.ddrID != undefined) {
      this.getPaymentMissedDetails(this.ddrID.replace('DDR-', ''));
    } else {
      this.router.navigate([this.navigationBackRoute()]);
    }

    this.missedPaymentsService
      .getMissedPaymentByDdrId$(this.activatedRoute.snapshot.params['ddrID'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((ddr) => {
        if (ddr) {
          this.plan = ddr;
        }
      });
  }

  navigationBackRoute() {
    if (this.isMissedPayment == 'true') {
      return 'payment-plan-summary/missed-payments/list';
    } else {
      return 'payment-plan-summary';
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showHeaderBasedPaymentPreference(): boolean {
    if (this.userSettingsService.getProviderPaymentPreference().value) {
      switch (this.userSettingsService.getProviderPaymentPreference().value) {
        case ProviderPaymentPreference.FirstOfTheMonth:
        case ProviderPaymentPreference.FifteenOfTheMonth:
        case ProviderPaymentPreference.FirstAndFifteenOfTheMonth: {
          return true;
        }
        default: {
          return false;
        }
      }
    }

    return true;
  }

  getPaymentMissedDetails(id: string) {
    this.subs.add(
      this.service.getPaymentMissedDetails(id).subscribe((response) => {
        if (response) {
          const missedPaymentDetails = response.data;
          this.mpDetails$.next(missedPaymentDetails);

          if (this.mpDetails$.value != null) {
            this.mpPlanDetails = this.mpDetails$.value[0];
            this.showRedTotalOverDueAmount = Number(this.mpPlanDetails.totalOverDueAmount) > 0 ? true : false;
            this.showRedCurrentMissedPayments = Number(this.mpPlanDetails.currentMissedPayments) > 0 ? true : false;

            if (this.currentTab === 0) {
              this.mpDetailsPaymentHistoryFiltered$.next(this.mpPlanDetails.history);
            } else {
              this.mpProviderActivityFiltered$.next(this.mpPlanDetails.historyProvider);
              this.calcTotal(this.mpProviderActivityFiltered$.value);
            }
          }
        }
      })
    );
  }

  calcTotal(providerPaymentHisList: any): void {
    this.totalProviderPaidToDate = 0;
    this.totalPaymentForecast = 0;
    this.totalPaymentSummary = 0;

    providerPaymentHisList.map((providerPaymentHis: PaymentMissedDetailsPaymentHistoryItemResponse) => {
      const historyItem = providerPaymentHis as PaymentMissedDetailsPaymentHistoryItemResponse;

      this.totalProviderPaidToDate = this.totalProviderPaidToDate + Number(historyItem.providerPaidToDate);
      this.totalPaymentForecast = this.totalPaymentForecast + Number(historyItem.paymentForecast);
      this.totalPaymentSummary = this.totalPaymentSummary + Number(historyItem.providerSummary);
    });
  }

  showPreview(): void {
    if (this.mpDetails$.value) {
      this.paymentService.getPDF(this.mpDetails$.value[0].scheduleDownloadKey).subscribe((response) => {
        if (response.data[0]) {
          this.previewPdf(response.data[0].files, this.ddrID + '.pdf');
        }
      });
    }
  }

  sendInvoice(): void {
    this.openSendInvoiceConfirmationDialog()
      .pipe(
        concatMap((proceed: boolean) => {
          if (proceed && this.plan) {
            return this.missedPaymentsService
              .sendInvoice$(this.plan['DDR Internal ID'])
              .pipe(map((res) => this.helperService.checkAPIResponse(res)));
          }
          return of(false);
        }),
        take(1)
      )
      .subscribe((isSent) => {
        if (isSent) {
          const successMsg = 'An invoice of the total overdue amount has been sent via SMS to the Responsible Party.';
          this.notificationService.success(successMsg, 'Invoice Sent');
          this.router.navigate([this.navigationBackRoute()]);
        }
      });
  }

  escalate(): void {
    this.openEscalateConfirmationDialog()
      .pipe(
        concatMap((isConfirmed) => {
          if (isConfirmed && this.plan) {
            const ddrId = this.plan['DDR'];
            return this.missedPaymentsService.escalateMissedPayment$(this.plan).pipe(
              map(() => {
                const successMsg = 'Payment ' + ddrId + ' has been escalated to APCA';
                this.notificationService.success(successMsg, 'Escalated');
              })
            );
          }
          return of(false);
        })
      )
      .pipe(take(1))
      .subscribe();
  }

  showSendInvoice(): boolean {
    return (
      this.userSettingsService.isPromptPayEnabled() === true &&
      Boolean(this.plan?.['Escalate to Collection Agency']) === false &&
      (this.plan?.['Invoice SMS Counter'] == '' ||
        Number(this.plan?.['Invoice SMS Counter']) < Number(this.userSettingsService.getPromptPaySmsLimit()))
    );
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentTab = tabChangeEvent.index;
    this.page = 0;
    this.itemPerPage = environment.itemPerPage;

    this.getPaymentMissedDetails(this.ddrID.replace('DDR-', ''));
  }

  onSort({ column, direction, type }: any) {
    if (this.currentTab == 0) {
      this.mpDetailsPaymentHistoryFiltered$.next(
        this.helperService.onSort(column, direction, this.mpDetailsPaymentHistoryFiltered$?.value, type)
      );
    } else {
      this.mpProviderActivityFiltered$.next(this.helperService.onSort(column, direction, this.mpProviderActivityFiltered$?.value, type));
      this.calcTotal(this.mpProviderActivityFiltered$.value);
    }
  }

  onSearchValueChange(event: SearchRequest): void {
    this.searchData = event;
    // reset focus to the first page
    this.page = 1;

    if (this.searchData.paymentStatus != undefined) {
      if (this.currentTab == 1) {
        this.mpProviderActivityFiltered$
          .pipe(
            filter((activities): activities is PaymentMissedProviderActivityItemResponse[] => activities !== null),
            map((activities) => activities.filter((activity) => activity.paymentStatus === this.searchData.paymentStatus))
          )
          .subscribe((filteredActivities) => {
            this.calcTotal(filteredActivities);
          });
      }
    } else {
      this.calcTotal(this.mpProviderActivityFiltered$.value);
    }
  }

  downloadRemittance(plan: any, key: any): void {
    this.financialService
      .getPaymentPDF(key)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.data[0] && response.data[0].files) {
          const pdfInBase64 = response.data[0].files;
          const linkSource = `data:application/pdf;base64,${pdfInBase64}`;
          const downloadLink = document.createElement('a');
          const fileName = plan.id + '.pdf';

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      });
  }

  private openSendInvoiceConfirmationDialog(): Observable<boolean> {
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      title: 'Send Invoice',
      content: 'send_invoice_message',
      langConfigKey: this.LANG_CONFIG_KEY,
      buttons: [
        {
          text: 'Cancel',
          class: 'btn btn-grey w-170 mr-1',
          value: false
        },
        {
          text: 'SMS Invoice',
          class: 'btn btn-primary w-170 ml-1',
          value: true
        }
      ]
    };

    return modalRef.closed.pipe(map((proceedWithInvoice) => proceedWithInvoice));
  }

  private openEscalateConfirmationDialog(): Observable<boolean> {
    const modalRef = this.modal.open(ModalComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.data = {
      title: 'Esacalate To Agency',
      content:
        parseFloat(this.userSettingsService.getConnectServices()?.debtCollectionEscalationFee) > 0
          ? 'escalate_with_fees_message'
          : 'escalate_without_fees_message',
      langConfigKey: this.LANG_CONFIG_KEY,
      strReplacements: [this.userSettingsService.getConnectServices()?.debtCollectionEscalationFee],
      buttons: [
        {
          text: 'Cancel',
          class: 'btn btn-grey w-170 mr-1',
          value: false
        },
        {
          text: 'Transfer to APCA',
          class: 'btn btn-primary w-170 ml-1',
          value: true
        }
      ]
    };

    return modalRef.closed.pipe(map((isConfirmed) => isConfirmed));
  }

  private setConfig(): void {
    this.previewBtnConfig$ = this.service.getPreviewBtnConfig();
    this.sendInvoiceBtnConfig$ = this.service.getSendInvoiceBtnConfig();
    this.escalateBtnConfig$ = this.service.getEscalateBtnConfig();

    this.searchWidgetConfig$ = combineLatest([this.service.getSearchWidgetConfig(), this.mpDetailsPaymentHistoryFiltered$]).pipe(
      map(([rawConfigs, paymentPlans]) => {
        return rawConfigs.map((config) => {
          const cloneConfig: SearchConfig = { ...config };

          if (config.name === 'paymentStatus') {
            cloneConfig.config = {
              ...cloneConfig.config,
              options: this.helperService.getUniqueObjectValuesFromArray(paymentPlans || [], 'paymentStatus')
            };
          }

          return cloneConfig;
        });
      })
    );
  }

  private previewPdf(files: any, fileName: string): void {
    const pdfInBase64 = files;
    const byteArray = new Uint8Array(
      atob(pdfInBase64)
        .split('')
        .map((char) => char.charCodeAt(0))
    );
    const modal = this.modal.open(ModalPdfViewerComponent, {
      centered: true,
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    });
    modal.componentInstance.data = {
      title: 'Preview',
      pdfInBase64,
      pdfInUint8Array: byteArray,
      fileName
    };
  }
}
