<sliqpay-header [pageTitle]="'page_title' | lang: LANG_CONFIG_KEY | async" [navigateBackTo]="navigationBackRoute()">
  <div class="d-flex flex-column align-items-center">
    <div class="action-buttons">
      <sliqpay-button
        [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.PROVIDER_PAYMENT_PLAN_ACTIVITY"
        [config]="previewBtnConfig$ | async"
        (clicked)="showPreview()"
      ></sliqpay-button>

      <sliqpay-button
        *ngIf="showSendInvoice()"
        [config]="sendInvoiceBtnConfig$ | async"
        (clicked)="sendInvoice()"
      ></sliqpay-button>

      <sliqpay-button
        *ngIf="plan && plan['Over 60 Days'] === '1' && !plan['Escalate to Collection Agency']"
        [config]="escalateBtnConfig$ | async"
        (clicked)="escalate()"
      ></sliqpay-button>
    </div>
  </div>
</sliqpay-header>

<div class="rounded" *ngIf="mpDetails$ | async">
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6">
          <h6 class="pl-0">{{ mpPlanDetails.rpName }}</h6>
          <h6 class="pl-0 mb-0">Payment plan number - {{ this.ddrID }}</h6>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="px-0 pt-2 col-md-6 col-sm-12" *ngIf="showHeaderBasedPaymentPreference()">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">
                <span class="sorting" [innerHTML]="'rp_details' | lang: LANG_CONFIG_KEY | async"></span>
              </h5>
              <label class="card-text mb-0"
                ><span class="sorting" [innerHTML]="'rp_name' | lang: LANG_CONFIG_KEY | async"></span>:
                {{ mpPlanDetails.rpName }}</label
              >
              <br />
              <label class="card-text mb-0"
                ><span class="sorting" [innerHTML]="'dob' | lang: LANG_CONFIG_KEY | async"></span>:
                {{ mpPlanDetails.rpDob }}</label
              >
              <br />
              <label class="card-text mb-0"
                ><span class="sorting" [innerHTML]="'mobile_num' | lang: LANG_CONFIG_KEY | async"></span>:
                {{ mpPlanDetails.rpMobile }}</label
              >
              <br />
              <label class="card-text mb-0"
                ><span class="sorting" [innerHTML]="'email' | lang: LANG_CONFIG_KEY | async"></span>:
                {{ mpPlanDetails.rpEmail }}</label
              >
              <br />
              <br />
              <hr />
              <h5 class="card-title">
                <span class="sorting" [innerHTML]="'patient_details' | lang: LANG_CONFIG_KEY | async"></span>
              </h5>
              <label class="card-text mb-0"
                ><span class="sorting" [innerHTML]="'rp_name' | lang: LANG_CONFIG_KEY | async"></span>:
                {{ mpPlanDetails.patientName }}</label
              >
              <br />
              <label class="card-text mb-0"
                ><span class="sorting" [innerHTML]="'dob' | lang: LANG_CONFIG_KEY | async"></span>:
                {{ mpPlanDetails.patientDob }}</label
              >
              <br />
              <label class="card-text mb-0"
                ><span class="sorting" [innerHTML]="'patient_id_num' | lang: LANG_CONFIG_KEY | async"></span>:
                {{ mpPlanDetails.patientIdNo }}</label
              >
              <br />
            </div>
          </div>
        </div>

        <div class="px-0 pt-2 col-md-6 col-sm-12" *ngIf="!showHeaderBasedPaymentPreference()">
          <div class="card h-100">
            <div class="card-body">
              <div class="row">
                <div class="pr-0 col-6">
                  <h5 class="card-title">
                    <span class="sorting" [innerHTML]="'rp_details' | lang: LANG_CONFIG_KEY | async"></span>
                  </h5>
                  <div class="card h-90" [style.height.px]="getElementHeight.offsetHeight">
                    <div class="card-body">
                      <label class="card-text mb-0"
                        ><span class="sorting" [innerHTML]="'rp_name' | lang: LANG_CONFIG_KEY | async"></span>:
                        {{ mpPlanDetails.rpName }}</label
                      >
                      <br />
                      <label class="card-text mb-0"
                        ><span class="sorting" [innerHTML]="'dob' | lang: LANG_CONFIG_KEY | async"></span>:
                        {{ mpPlanDetails.rpDob }}</label
                      >
                      <br />
                      <label class="card-text mb-0"
                        ><span class="sorting" [innerHTML]="'mobile_num' | lang: LANG_CONFIG_KEY | async"></span>:
                        {{ mpPlanDetails.rpMobile }}</label
                      >
                      <br />
                      <label class="card-text mb-0"
                        ><span class="sorting" [innerHTML]="'email' | lang: LANG_CONFIG_KEY | async"></span>:
                        {{ mpPlanDetails.rpEmail }}</label
                      >
                      <br />
                      <hr />
                      <h5 class="card-title">
                        <span class="sorting" [innerHTML]="'patient_details' | lang: LANG_CONFIG_KEY | async"></span>
                      </h5>
                      <label class="card-text mb-0"
                        ><span class="sorting" [innerHTML]="'rp_name' | lang: LANG_CONFIG_KEY | async"></span>:
                        {{ mpPlanDetails.patientName }}</label
                      >
                      <br />
                      <label class="card-text mb-0"
                        ><span class="sorting" [innerHTML]="'dob' | lang: LANG_CONFIG_KEY | async"></span>:
                        {{ mpPlanDetails.patientDob }}</label
                      >
                      <br />
                      <label class="card-text"
                        ><span class="sorting" [innerHTML]="'patient_id_num' | lang: LANG_CONFIG_KEY | async"></span>:
                        {{ mpPlanDetails.patientIdNo }}</label
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div
                  class="col-6"
                  [sliqpayProviderConfigFeatureFlag]="ProviderConfigFeature.PROVIDER_PAYMENT_PLAN_ACTIVITY"
                >
                  <h5 class="card-title">
                    <span class="sorting" [innerHTML]="'payment_plan_summary' | lang: LANG_CONFIG_KEY | async"></span>
                  </h5>
                  <div
                    #getElementHeight
                    class="card h-90"
                  >
                    <div class="card-body">
                      <label class="card-text"
                        ><span class="sorting" [innerHTML]="'rp_ptd' | lang: LANG_CONFIG_KEY | async"></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.rpPaidToDate | currency }}</strong>
                      <hr />
                      <label class="card-text"
                        ><span class="sorting" [innerHTML]="'rp_remaining_amt' | lang: LANG_CONFIG_KEY | async"></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.rpRemainingAmount | currency }}</strong>
                      <hr />
                      <label class="card-text"
                        ><span class="sorting" [innerHTML]="'provider_ptd' | lang: LANG_CONFIG_KEY | async"></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.providerPaidToDate | currency }}</strong>
                      <hr />
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'provider_remaining_amt' | lang: LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.providerRemainingAmount | currency }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="pr-0 pt-2 col-md-6 col-sm-12">
          <div class="card h-100">
            <div class="card-body">
              <h5 class="card-title">
                <span class="sorting" [innerHTML]="'missed_payment_activity' | lang: LANG_CONFIG_KEY | async"></span>
              </h5>

              <div class="row">
                <div class="pr-0 col-6">
                  <div class="card h-100">
                    <div class="card-body">
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'total_outstanding_amount' | lang: LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <h6 [ngClass]="{ 'card-text': true, 'text-danger': showRedTotalOverDueAmount }">
                        <strong>{{ mpPlanDetails.totalOverDueAmount | currency }}</strong>
                      </h6>
                      <hr />
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'total_plan_outstanding_amount' | lang: LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.totalArrearsAmount | currency }}</strong>
                      <hr />
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'total_outstanding_fees' | lang: LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.totalOverDueFee | currency }}</strong>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="card h-100">
                    <div class="card-body">
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'current_missed_payments' | lang: LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <h6 [ngClass]="{ 'card-text': true, 'text-danger': showRedCurrentMissedPayments }">
                        <strong>{{ mpPlanDetails.currentMissedPayments }}</strong>
                      </h6>
                      <hr />
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'last_missed_payments' | lang: LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.lastMissed }}</strong>
                      <hr />
                      <label class="card-text"
                        ><span
                          class="sorting"
                          [innerHTML]="'last_contact_activity' | lang: LANG_CONFIG_KEY | async"
                        ></span
                      ></label>
                      <br />
                      <strong class="card-text">{{ mpPlanDetails.lastContact }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content custom-margin-top">
    <sliqpay-list-search-widget
      class="custom_search"
      [config]="(searchWidgetConfig$ | async) ?? []"
      (searchValue)="onSearchValueChange($event)"
    ></sliqpay-list-search-widget>

    <div class="bg-white container-fluid search-tab-container">
      <mat-tab-group (selectedTabChange)="tabChanged($event)">
        <!-- RP Payment Plan Activity -->
        <mat-tab label="RP Payment Plan Activity">
          <br />
          <div class="row" *ngIf="mpDetailsPaymentHistoryFiltered$ | async">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" sliqpaySortable="dueDate" (sort)="onSort($event)" type="date">
                        <span class="sorting" [innerHTML]="'payment_date' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th scope="col" sliqpaySortable="reschedPaymentDate" (sort)="onSort($event)" type="date">
                        <span class="sorting" [innerHTML]="'rescheduled_date' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th scope="col" sliqpaySortable="rpElementType" (sort)="onSort($event)" type="date">
                        <span class="sorting" [innerHTML]="'payment_type' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th scope="col" sliqpaySortable="scheduledAmount" (sort)="onSort($event)">
                        <span class="sorting" [innerHTML]="'scheduled_amount' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th scope="col" sliqpaySortable="amountPaid" (sort)="onSort($event)">
                        <span class="sorting" [innerHTML]="'paid_amount' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th scope="col" sliqpaySortable="paymentStatus" (sort)="onSort($event)">
                        <span class="sorting" [innerHTML]="'payment_status' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let mpDetailsPaymentHistoryItem of (mpDetailsPaymentHistoryFiltered$ | async) || []
                          | searchFilter: searchData
                          | paginate: { itemsPerPage: itemPerPage, currentPage: page }
                      "
                    >
                      <td>{{ mpDetailsPaymentHistoryItem.dueDate }}</td>
                      <td>{{ mpDetailsPaymentHistoryItem.reschedPaymentDate }}</td>
                      <td>{{ mpDetailsPaymentHistoryItem.rpElementType }}</td>
                      <td>{{ mpDetailsPaymentHistoryItem.scheduledAmount | currency }}</td>
                      <td>{{ mpDetailsPaymentHistoryItem.amountPaid | currency }}</td>
                      <td>{{ mpDetailsPaymentHistoryItem.paymentStatus }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <sliqpay-empty-screen
            *ngIf="
              (mpDetailsPaymentHistoryFiltered$ | async) === null ||
              (mpDetailsPaymentHistoryFiltered$ | async)?.length === 0
            "
            text="No missed payments details"
          ></sliqpay-empty-screen
        ></mat-tab>

        <!-- Provider Payment Plan Activity  -->
        <mat-tab
          label="Provider Payment Plan Activity"
          *ngIf="this.userSettingsService.isProviderPaymentPlanActivityEnabled()"
        >
          <br />
          <div class="row" *ngIf="mpProviderActivityFiltered$ | async">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        style="width: 15%"
                        scope="col"
                        sliqpaySortable="scheduledPaymentDate"
                        (sort)="onSort($event)"
                        type="date"
                      >
                        <span class="sorting" [innerHTML]="'payment_date' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th style="width: 15%" scope="col" sliqpaySortable="paymentStatus" (sort)="onSort($event)">
                        <span class="sorting" [innerHTML]="'payment_status' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th style="width: 17%" scope="col" sliqpaySortable="providerPaidToDate" (sort)="onSort($event)">
                        <span class="sorting" [innerHTML]="'provider_ptd' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th style="width: 17%" scope="col" sliqpaySortable="paymentForecast" (sort)="onSort($event)">
                        <span class="sorting" [innerHTML]="'payment_forecast' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th style="width: 17%" scope="col" sliqpaySortable="providerSummary" (sort)="onSort($event)">
                        <span class="sorting" [innerHTML]="'payment_summary' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                      <th style="width: 17%" scope="col" sliqpaySortable="remittanceNumber" (sort)="onSort($event)">
                        <span class="sorting" [innerHTML]="'remittance_number' | lang: LANG_CONFIG_KEY | async"></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let mpDetailsPaymentHistoryItem of (mpProviderActivityFiltered$ | async) || []
                          | searchFilter: searchData
                          | paginate: { itemsPerPage: itemPerPage, currentPage: page }
                      "
                    >
                      <td>{{ mpDetailsPaymentHistoryItem.scheduledPaymentDate }}</td>
                      <td>{{ mpDetailsPaymentHistoryItem.paymentStatus }}</td>
                      <td>{{ mpDetailsPaymentHistoryItem.providerPaidToDate | currency }}</td>
                      <td>{{ mpDetailsPaymentHistoryItem.paymentForecast | currency }}</td>
                      <td>{{ mpDetailsPaymentHistoryItem.providerSummary | currency }}</td>
                      <td
                        class="navColor"
                        (click)="
                          downloadRemittance(
                            mpDetailsPaymentHistoryItem.remittanceNumber,
                            mpDetailsPaymentHistoryItem.remittanceDownloadKey
                          )
                        "
                      >
                        {{ mpDetailsPaymentHistoryItem.remittanceNumber.text }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>{{ totalProviderPaidToDate | currency }}</td>
                      <td>{{ totalPaymentForecast | currency }}</td>
                      <td>{{ totalPaymentSummary | currency }}</td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <sliqpay-empty-screen
            *ngIf="(mpProviderActivityFiltered$ | async)?.length === 0"
            text="No missed payments details"
          ></sliqpay-empty-screen
        ></mat-tab>
      </mat-tab-group>
    </div>

    <sliqpay-pagination
      [(page)]="page"
      [(itemPerPage)]="itemPerPage"
      *ngIf="currentTab === 0 && (mpDetailsPaymentHistoryFiltered$ | async)"
    ></sliqpay-pagination>

    <sliqpay-pagination
      [(page)]="page"
      [(itemPerPage)]="itemPerPage"
      *ngIf="currentTab === 1 && (mpProviderActivityFiltered$ | async)"
    ></sliqpay-pagination>
  </div>
</div>
